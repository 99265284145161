



















import { Component, Prop } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import PillListComponent from '@/components/pill/PillListComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { PillListComponent },
})
export default class GenericPillListComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  @Prop({ required: false, default: null })
  private readonly titleSize!: string;

  @Prop({ required: false, default: null })
  private readonly size!: string;

  created(): void {
    this.setDataConfig();
  }
}
